export async function toBlob(data) {
    const req = await fetch(`data:image/jpeg;base64,${data}`);
    return await req.blob();
}

export function createImageElement(src) {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve(img);
        };
        img.src = src;
    });
}

function drawText(canvasContext, text, x, y, spacing) {
    let start = x;

    if (spacing) {
        text.split('').forEach((c) => {
            if (c !== ' ') {
                canvasContext.fillText(c, start, y);
                const text = canvasContext.measureText(c);
                start += text.width + spacing.letter;
            } else {
                start += spacing.word;
            }
        });
    } else {
        canvasContext.fillText(text, x, y);
    }
}

export async function drawOverlay({
    canvasContext,
    width,
    height,
    artist,
    song,
    mint,
    nickname,
    ownerImage,
    popinsImage,
    // poweredByImage,
}) {
    /**
     * Draw texts.
     */
    const getFont = (size) => `500 ${size}px "Europa Bold"`;
    const normalLineHeight = 22;
    const bigLineHeight = 32;
    const beginY = 160;
    const leftX = 16;

    canvasContext.shadowColor = 'rgba(0, 0, 0, 0.4)';
    canvasContext.shadowBlur = 3;
    canvasContext.shadowOffsetX = 3;
    canvasContext.shadowOffsetY = 3;
    canvasContext.fillStyle = 'white';

    canvasContext.font = getFont(26);
    drawText(canvasContext, artist, leftX, height - beginY, {
        letter: 2,
        word: 12,
    });

    canvasContext.font = getFont(18);
    drawText(canvasContext, song, leftX, height - (beginY - normalLineHeight));
    drawText(canvasContext, mint, leftX, height - (beginY - normalLineHeight - normalLineHeight));
    drawText(
        canvasContext,
        nickname,
        leftX + ownerImage.width + 8, // 8px is the gap between avatar and text
        height - (beginY - normalLineHeight - normalLineHeight - bigLineHeight)
    );

    /**
     * Draw images.
     */
    canvasContext.imageSmoothingQuality = 'high';

    // const poweredByImageX = (width - poweredByImage.width) / 2;
    // const poweredByImageY = height - poweredByImage.height - 6;
    const popinsLogoX = width - popinsImage.width - 16;
    const popinsLogoY = height - popinsImage.height - 80;

    canvasContext.drawImage(popinsImage.el, popinsLogoX, popinsLogoY, popinsImage.width, popinsImage.height);
    // canvasContext.drawImage(
    //     poweredByImage.el,
    //     poweredByImageX,
    //     poweredByImageY,
    //     poweredByImage.width,
    //     poweredByImage.height
    // );

    const radius = ownerImage.width / 2;
    canvasContext.save();
    canvasContext.beginPath();
    canvasContext.arc(
        leftX + radius,
        height - (beginY - normalLineHeight - normalLineHeight - radius - 10), // 10: adjustment
        radius,
        0,
        Math.PI * 2,
        true
    );
    canvasContext.closePath();
    canvasContext.clip();
    canvasContext.drawImage(
        ownerImage.el,
        leftX,
        height - (beginY - normalLineHeight - normalLineHeight - 10),
        ownerImage.width,
        ownerImage.height
    );
    canvasContext.restore();
}

export async function fetchOwnerAvatar(link) {
    try {
        const req = await fetch(`${window.API_BASE_URL}/download-image?url=${link}`);
        if (req.status === 200) {
            const data = await req.json();
            return data.data;
        } else {
            throw new Error('Could not fetch the image');
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}
