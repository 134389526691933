import 'sanitize.css';
import './mdc.scss';
import './styles.scss';
import './assets/spinkit.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { omnivorPipelineModule } from './ov_pipeline_module.js';
import CookieView from './views/cookie';

const kLocalStorageKey = 'OVPOPINS_COOKIES_ACCEPTED';

const updateWindowSize = () => {
    const doc = document.documentElement;
    const device = XR8.XrDevice.deviceEstimate();
    doc.style.setProperty('--full-width', `${window.innerWidth}px`);
    if (device.os == 'Android') {
        doc.style.setProperty('--full-height', `${window.innerHeight}px`);
    }
};

const onxrloaded = () => {
    XR8.addCameraPipelineModules([
        // Existing pipeline modules.
        XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
        XR8.Threejs.pipelineModule(), // Creates a ThreeJS AR Scene.
        XR8.XrController.pipelineModule(), // Enables SLAM tracking.
        XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
        XRExtras.FullWindowCanvas.pipelineModule(), // Modifies the canvas to fill the window.
        XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
        XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
        XR8.canvasScreenshot().cameraPipelineModule(),

        // Custom pipeline modules.
        omnivorPipelineModule(), // Sets up the threejs camera and scene content.
    ]);

    // Open the camera and start running the camera run loop.
    const canvas = document.getElementById('camerafeed');
    XR8.run({ canvas });

    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
};

const cookiesAccepted = () => {
    return localStorage.getItem(kLocalStorageKey) === 'true';
};

const doLoad = () => {
    // Show loading screen before the full XR library has been loaded.
    const load = () => {
        XRExtras.Loading.showLoading({ onxrloaded });
    };
    window.XRExtras ? load() : window.addEventListener('xrextrasloaded', load);
};

const handleAccept = () => {
    try {
        localStorage.setItem(kLocalStorageKey, 'true');
    } catch (error) {
        console.error(error);
    }
    CookieView.unmount();
    doLoad();
};

let notYet;
const check = () => {
    if (!notYet) {
        notYet = true;

        if (!cookiesAccepted()) {
            CookieView.mount(handleAccept);
        } else {
            doLoad();
        }
    }
};

window.addEventListener('DOMContentLoaded', check);
window.onload = check;
