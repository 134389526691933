import { el, mount, unmount } from 'redom';
import close from '../assets/close.svg';

class Preview {
    constructor(attr) {
        <div this="el" class="preview-screenshot">
            <div this="flashEl" class="flash-element"></div>
            <div class="img-container">
                <img this="imgEl" name="Popins screenshot" alt="Popins screenshot" class="screenshot hidden" />
                <div this="buttonsEl" class="hidden bottom-buttons">
                    <button this="closeEl" class="mdc-icon-button common-button" aria-label="Close" aria-pressed="true">
                        <img src={close} class="mdc-icon-button__icon" aria-hidden="true" />
                    </button>
                </div>
            </div>
            <div this="tapholdEl" class="hidden tap-hold">
                <span>Tap and hold on image to share</span>
            </div>
        </div>;

        this.imgEl.src = `data:image/jpeg;base64,${attr.imageSrc}`;
        this.closeEl.onclick = () => {
            Preview.unmount();
            attr.onClose();
        };
        this.el.onanimationend = () => {
            this.flashEl.classList.remove('flashing');
            this.el.classList.add('dim');
            this.buttonsEl.classList.remove('hidden');
            this.imgEl.classList.remove('hidden');
            this.tapholdEl.classList.remove('hidden');
        };
    }
}

let instance;

Preview.mount = ({ imageSrc, onClose }) => {
    instance = <Preview imageSrc={imageSrc} onClose={onClose} />;
    mount(document.body, instance);
    return instance;
};

Preview.unmount = () => {
    if (instance) {
        unmount(document.body, instance);
    }
    instance = null;
};

export default Preview;
