import { el, mount, unmount } from 'redom';

class CookieView {
    constructor(onAccept) {
        <div this="el" class="fullpage absolute-fill cookie">
            <div class="content center">
                <img class="logo" src="assets/popins-logo.png" alt="Popins" />
            </div>
            <div class="dialog">
                <p>
                    We use cookies to ensure you get the best experience on our website. For more
                    information, please see our <a href="https://www.popins.io/privacy-policy">privacy policy</a>.
                </p>
                <button onclick={onAccept}>Accept</button>
            </div>
        </div>;
    }
}

let instance;

CookieView.mount = (onAccept) => {
    instance = new CookieView(onAccept);
    mount(document.body, instance);
};

CookieView.unmount = () => {
    if (instance) {
        unmount(document.body, instance);
    }
    instance = null;
};

export default CookieView;
