import { el } from 'redom';
import { MDCRipple } from '@material/ripple';

export const localStorageKey = 'OVPOPINS_FRE_VIEWED';

export default class FRE {
    constructor(attr) {
        this.onClose = attr.onClose;
        this.el = (
            <div class="fre">
                <div class="fre-content">
                    <div class="fre1" this="fre1El">
                        <h4>WELCOME TO POPINS</h4>
                        <p>Aim your device at a flat space and tap the screen</p>
                    </div>
                    <div class="fre2 hidden" this="fre2El">
                        <h4>EXPLORE</h4>
                        <p>
                            Use a two-finger pinch to enhance the details of the Popin &#x2022;
                            Swipe to rotate the Popin &#x2022; Tap the screen again to place the
                            Popin in a new location
                        </p>
                    </div>
                </div>
                <div class="fre-foot">
                    <button this="skipEl" class="skip common-button mdc-button">
                        <span class="mdc-button__ripple"></span>
                        <span class="mdc-button__label">Skip</span>
                    </button>
                    <button this="nextEl" class="mdc-button next common-button">
                        <span class="mdc-button__ripple"></span>
                        <span class="mdc-button__label">Next</span>
                    </button>
                    <button this="closeEl" class="close hidden common-button mdc-button">
                        <span class="mdc-button__ripple"></span>
                        <span class="mdc-button__label">Close</span>
                    </button>
                </div>
            </div>
        );

        const close = function (evt) {
            this.onClose(evt);
            this.reset();
        }.bind(this);

        this.skipEl.onclick = close;
        this.closeEl.onclick = close;
        this.nextEl.onclick = this.next.bind(this);

        new MDCRipple(this.nextEl);
        new MDCRipple(this.closeEl);
        new MDCRipple(this.skipEl);
    }

    getFREBoxSize() {
        const fre1Rect = this.fre1El.getBoundingClientRect();
        const fre2Rect = this.fre2El.getBoundingClientRect();
        document.documentElement.style.setProperty(
            '--fre-height',
            `${Math.max(fre1Rect.height, fre2Rect.height)}px`
        );
        document.documentElement.style.setProperty(
            '--fre-width',
            `${Math.max(fre1Rect.width, fre2Rect.width)}px`
        );
    }

    onmount() {
        if (localStorage.getItem(localStorageKey) !== 'true') {
            try {
                localStorage.setItem(localStorageKey, 'true');
            } catch (error) {
                console.error(error);
            }
        }

        this.getFREBoxSize();
    }

    onremount() {
        this.getFREBoxSize();
    }

    reset() {
        this.fre1El.classList.remove('hidden');
        this.nextEl.classList.remove('hidden');
        this.skipEl.classList.remove('hidden');
        this.fre2El.classList.add('hidden');
        this.closeEl.classList.add('hidden');
    }

    next() {
        this.fre1El.classList.add('hidden');
        this.nextEl.classList.add('hidden');
        this.skipEl.classList.add('hidden');
        this.fre2El.classList.remove('hidden');
        this.closeEl.classList.remove('hidden');

        this.getFREBoxSize();
    }
}
