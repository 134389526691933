import { el, mount, setChildren } from 'redom';
import FRE, { localStorageKey } from './fre';
import help from '../assets/help.svg';
import offImg from '../assets/volume_off.svg';
import onImg from '../assets/volume_up.svg';
import Loading from './loading';
import cameraImg from '../assets/camera.svg';
import rewindImg from '../assets/rewind.svg';
import forwardImg from '../assets/forward.svg';
import playImg from '../assets/play.svg';
import pauseImg from '../assets/pause.svg';

class ExploreView {
    IsFREOnDuty = false;
    IsTipOnDuty = false;

    constructor(attr) {
        this.onVolume = attr.onVolume;
        this.onCloseFRE = attr.onCloseFRE;
        this.el = (
            <div class="explore absolute-fill">
                <div class="row">
                    <button this="helpEl" class="mdc-icon-button common-button" aria-label="Help" aria-pressed="true">
                        <img src={help} class="mdc-icon-button__icon" aria-hidden="true" />
                    </button>
                </div>
                <div class="row">
                    <button
                        this="volumeEl"
                        class="mdc-icon-button common-button mdc-icon-button--on"
                        aria-label="Help"
                        aria-pressed="true"
                    >
                        <img class="mdc-icon-button__icon" src={offImg} />
                        <img class="mdc-icon-button__icon mdc-icon-button__icon--on" src={onImg} />
                    </button>
                </div>
                <div class="row">
                    <button
                        this="screenShotEl"
                        class="disabled mdc-icon-button common-button camera"
                        title="Take a screenshot"
                    >
                        <img src={cameraImg} class="mdc-icon-button__icon" />
                    </button>
                </div>
                <div class="body" this="bodyEl" />
                <div class="row foot" this="footEl" />
                <div class="playback-row hidden" this="playbackControlsEl">
                    <div>
                        <button
                            this="rewindEl"
                            title="Rewind"
                            class="playback-button"
                        >
                            <img src={rewindImg} />
                        </button>
                    </div>
                    <div>
                        <button
                            this="playPauseEl"
                            title="Play/Pause"
                            class="playback-button"
                        >
                            <img id="playpause-img" src={playImg} />
                        </button>
                    </div>
                    <div>
                        <button
                            this="forwardEl"
                            title="Forward"
                            class="playback-button"
                        >
                            <img src={forwardImg} />
                        </button>
                    </div>
                </div>
                {/* <div class="row powered-by" /> */}
            </div>
        );

        this.showFRE = this.showFRE.bind(this);
        this.showTip = this.showTip.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.handleVolume = this.handleVolume.bind(this);
        this.clearBody = this.clearBody.bind(this);

        this.volumeEl.onclick = this.handleVolume;
        this.screenShotEl.onclick = (evt) => {
            if (this.screenShotEl.classList.contains('disabled')) {
                return;
            }
            attr.onScreenShot(this, evt);
        };
        this.helpEl.onclick = () => {
            if (this.IsFREOnDuty) {
                this.clearBody();
                this.onCloseFRE(this);
            } else {
                this.showFRE();
            }
        };

        this.freEl = (
            <FRE
                onClose={function () {
                    this.clearBody();
                    this.onCloseFRE(this);
                }.bind(this)}
            />
        );
        this.guideEl = <div class="tip">Tap the screen to place the Popin</div>;
        this.loadingEl = <Loading this="spinnerEl" />;

        this.playPauseEl.onclick = attr.onPlayPause;
        this.rewindEl.onclick = () => attr.onSeeking(-10);
        this.forwardEl.onclick = () => attr.onSeeking(10);
    }

    set ClipData(value) {
        this.clipData = value;
        this.enableCamera();
    }

    get ClipData() {
        return this.clipData;
    }

    set OwnerAvatarBase64(value) {
        this.ownerAvatarBase64 = value;
        this.enableCamera();
    }

    get OwnerAvatarBase64() {
        return this.ownerAvatarBase64;
    }

    setClipInfo(clipData) {
        this.ClipData = clipData;
        setChildren(this.footEl, [
            <div class="singer">{this.clipData.artistName}</div>,
            <div>'{this.clipData.songName}'</div>,
            <div>
                {this.clipData.mint} of {this.clipData.totalMint}
            </div>,
            <div class="owner">
                <img src={this.clipData.ownerAvatar} alt={this.clipData.ownerNickname} />
                <span>{this.clipData.ownerNickname}</span>
            </div>,
        ]);
    }

    clearClipInfo() {
        this.clipData = null;
        setChildren(this.footEl, []);
    }

    clearBody() {
        this.IsFREOnDuty = false;
        this.IsTipOnDuty = false;
        setChildren(this.bodyEl, []);
    }

    enableCamera() {
        if (this.clipData && this.ownerAvatarBase64) {
            this.screenShotEl.classList.remove('disabled');
        }
    }

    showTip() {
        this.IsFREOnDuty = false;
        this.IsTipOnDuty = true;
        setChildren(this.bodyEl, [this.guideEl]);
    }

    showFRE() {
        this.IsFREOnDuty = true;
        this.IsTipOnDuty = false;
        setChildren(this.bodyEl, [this.freEl]);
    }

    showError(message) {
        this.IsFREOnDuty = false;
        this.IsTipOnDuty = false;
        setChildren(this.bodyEl, [<div class="error tip">{message}</div>]);
    }

    showLoading() {
        this.IsFREOnDuty = false;
        this.IsTipOnDuty = false;
        setChildren(this.bodyEl, [this.loadingEl]);
    }

    showVolumeMute() {
        this.volumeEl.classList.remove('mdc-icon-button--on');
    }

    showVolumeOn() {
        this.volumeEl.classList.add('mdc-icon-button--on');
    }

    get IsVolumeOn() {
        return this.volumeEl.classList.contains('mdc-icon-button--on');
    }

    handleVolume(evt) {
        this.onVolume(this.IsVolumeOn, evt);
    }

    get IsFREShown() {
        return localStorage.getItem(localStorageKey) === 'true';
    }

    showPlaybackControls() {
        this.playbackControlsEl.classList.remove('hidden');
    }

    showPause() {
        document.getElementById('playpause-img').src = pauseImg;
    }

    showPlay() {
        document.getElementById('playpause-img').src = playImg;
    }
}

let instance;

ExploreView.mount = ({ onVolume, onCloseFRE, onScreenShot, onPlayPause, onSeeking }) => {
    instance = new ExploreView({ onVolume, onCloseFRE, onScreenShot, onPlayPause, onSeeking });
    mount(document.getElementById('popins'), instance);
    return instance;
};

ExploreView.unmount = () => {
    if (instance) {
        unmount(document.getElementById('popins'), instance);
    }
    instance = null;
};

export default ExploreView;
