import { el, mount, setChildren, unmount } from 'redom';
import replayImg from '../assets/replay.png';
import shareCircle from '../assets/share-circle.png';
import threeDotsCircle from '../assets/three-dots-circle.png';
import linkImg from '../assets/link.png';
import shareImg from '../assets/share.png';
import popinsLogo from '../assets/popins-logo.png';
import xLogo from '../assets/x.png';
import facebookLogo from '../assets/facebook.png';
import instagramLogo from '../assets/instagram.png';
import Loading from './loading';

class BottomDrawer {
    constructor() {
        this.el = (
            <div this="overlay" class="bottom-drawer-overlay hidden">
                <div this="drawer" class="bottom-drawer">
                </div>
            </div>
        );
    }

    show() {
        // hide all other drawers
        document.querySelectorAll('.bottom-drawer-overlay').forEach((i) => {
            i.classList.add('hidden');
        });
        this.overlay.classList.remove('hidden');
    }

    hide() {
        this.overlay.classList.add('hidden');
    }
}

class ShareDrawer extends BottomDrawer {
    constructor() {
        super();

        this.copyEl = (<li>
            <img src={linkImg} alt="Copy link" />
            Copy link
        </li>);
        this.shareEl = (<li>
            <img src={shareImg} alt="Share" />
            Share popin via...
        </li>);
        this.cancelBtnEl = <li><button>Cancel</button></li>;

        const list = el("ul");
        setChildren(list, [this.copyEl, this.shareEl, this.cancelBtnEl]);
        setChildren(this.drawer, list);

        this.copyEl.onclick = (function() {
            navigator.clipboard.writeText(window.location.href);
            this.hide();
        }).bind(this);

        this.shareEl.onclick = (function() {
            if (navigator.share) {
                navigator
                    .share({
                        url: window.location.href,
                    })
                    .then(() => this.hide())
                    .catch((e) => console.log(e))
            }
        }).bind(this);

        this.cancelBtnEl.onclick = (function() {
            this.hide();
        }).bind(this);
    }
}

class MoreDrawer extends BottomDrawer {
    constructor(attr) {
        super();

        this.learnMoreEl = (<li>
            <a href="https://popins.io" target="_blank" rel="noopener">
                <img src={popinsLogo} alt="Popins" />
                Learn more about Popins
            </a>
        </li>);
        const socialMap = [
            {
                name: 'Facebook',
                regex: /www\.facebook\.com/,
                logo: facebookLogo,
            },
            {
                name: 'X (Twitter)',
                regex: /twitter\.com/,
                logo: xLogo,
            },
            {
                name: 'X (Twitter)',
                regex: /x\.com/,
                logo: xLogo
            },
            {
                name: 'Instagram',
                regex: /www\.instagram\.com/,
                logo: instagramLogo,
            },
        ];
        this.socialEl = attr.artistSocialAccounts?.map((account) => {
            const social = socialMap.find((i) => i.regex.test(account));
            if (!social) {
                return null;
            }
            return (
                <li>
                    <a href={account} target="_blank" rel="noopener noreferrer">
                        <img src={social.logo} alt={social.name} />
                        Follow {attr.artistName} on {social.name}
                    </a>
                </li>
            )
        });
        if (this.socialEl?.length) {
            this.socialEl.unshift(<hr style="color: white;" />)
        }
        this.cancelBtnEl = <li><button>Cancel</button></li>;

        const list = el("ul");
        setChildren(list, [this.learnMoreEl, ...(this.socialEl || []), this.cancelBtnEl]);
        setChildren(this.drawer, list);

        this.cancelBtnEl.onclick = (function() {
            this.hide();
        }).bind(this);
    }
}

class Poster {
    constructor(attr) {
        this.el = (
            <div
                this="poster"
                class={`poster ${attr.class || ''}`}
                style={`background-image: url(${attr.poster})`}
            >
                <div class="line mint">
                    <div
                        class="owner-avatar"
                        style={`background-image: url(${attr.ownerAvatar})`}
                    />
                    <div class="owner-nickname">{attr.ownerNickname}</div>
                    <div>
                        {attr.mint} of {attr.totalMint}
                    </div>
                </div>
                <div class="line artist">
                    <p>{attr.artistName}</p>
                    <p>'{attr.songName}'</p>
                </div>
                <div class="line end-icon mb8">
                    <button this="moreEl" title="More" class="common-button">
                        <img src={threeDotsCircle} alt="More" />
                    </button>
                </div>
                <div class="line end-icon">
                    <button this="shareEl" title="Share" class="common-button">
                        <img src={shareCircle} alt="Share" />
                    </button>
                </div>
                <div this="shareDrawerContainer"></div>
                <div this="moreDrawerContainer"></div>
            </div>
        );

        // Share drawer
        this.shareDrawerEl = new ShareDrawer();
        setChildren(this.shareDrawerContainer, this.shareDrawerEl);

        this.shareEl.onclick = (function() {
            this.shareDrawerEl.show();
        }).bind(this);


        // More/social drawer
        this.moreDrawerEl = new MoreDrawer(attr);
        setChildren(this.moreDrawerContainer, this.moreDrawerEl);

        this.moreEl.onclick = (function() {
            this.moreDrawerEl.show();
        }).bind(this);
    }
}

class EndCard {
    constructor(attr) {
        this.clip = attr.clip;

        this.el = (
            <div class="fullpage absolute-fill endcard">
                <div class="slot" this="slotEl"></div>
                <div class="foot" this="footEl">
                    <button this="replayEl" title="Replay" class="common-button">
                        <img src={replayImg} alt="Replay" />
                    </button>
                </div>
            </div>
        );

        this.replayEl.onclick = attr.replay;
    }

    overflow(visible) {
        if (visible) {
            document.body.classList.add('invisible-overflow');
        } else {
            document.body.classList.remove('invisible-overflow');
        }
    }

    onmount() {
        this.load(this.clip);
        this.overflow(true);
    }

    onremount() {
        this.load(this.clip);
        this.overflow(true);
    }

    onunmount() {
        this.overflow(false);
    }

    showPoster(clipInfo) {
        setChildren(this.slotEl, [
            <Poster
                poster={clipInfo.poster}
                artistLink={clipInfo.artistLink}
                artistName={clipInfo.artistName}
                popinsLink={clipInfo.popinsLink}
                ownerNickname={clipInfo.ownerNickname}
                ownerAvatar={clipInfo.ownerAvatar}
                songName={clipInfo.songName}
                mint={clipInfo.mint}
                totalMint={clipInfo.totalMint}
                artistSocialAccounts={clipInfo.artistSocialAccounts}
            />,
        ]);
    }

    load(clip) {
        if (clip.clipInfo) {
            this.showPoster(clip.clipInfo);
        } else {
            setChildren(this.slotEl, [<Loading />]);

            fetch(`${window.API_BASE_URL}/clips/${clip.clipId}`, {
                method: 'GET',
                mode: 'cors',
                referrerPolicy: 'origin',
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    return null;
                })
                .then((data) => {
                    this.showPoster(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}

let instance;

EndCard.mount = (clip, replay) => {
    instance = <EndCard clip={clip} replay={replay} />;
    mount(document.body, instance);
    return instance;
};

EndCard.unmount = () => {
    if (instance) {
        unmount(document.body, instance);
    }
    instance = null;
};

export default EndCard;
