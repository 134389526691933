import EndCard from './views/endcard.js';
import ExploreView from './views/explore.js';
import PreviewView from './views/preview';
import Clip from './utils/clip';
import poweredBySrc from './assets/FormaVision_Logo_White.png';
import popinsLogoSrc from './assets/popins-logo.png';
import { drawOverlay, fetchOwnerAvatar, createImageElement } from './utils/screenshot';

// Define an 8th Wall XR Camera Pipeline Module that adds Omnivor content to a threejs scene on startup.
export const omnivorPipelineModule = () => {
    // Animation constants.
    const animateOutScale = new THREE.Vector3(0.001, 0.001, 0.001);
    const animateInScale = new THREE.Vector3(1, 1, 1);
    const animationMillisBounce = 800;
    const animationMillisInOut = 150;
    const zoomScaleMin = 0.1;
    const zoomScaleMax = 3.0;

    // Tracks zoom/pan state.
    let zooming = false;
    let initialZoomScale = 1.0;
    let panning = false;
    let initialRotation = 0.0;
    let hasFirstTap = false;

    let exploreView;
    let activeClip = { update: () => {} };
    let camera;

    const animateIn = (bounce) => {
        const easing = bounce ? TWEEN.Easing.Elastic.Out : TWEEN.Easing.Cubic.InOut;
        const duration = bounce ? animationMillisBounce : animationMillisInOut;
        // Animate the scale back up.
        const scale = Object.assign({}, animateOutScale);
        new TWEEN.Tween(scale)
            .to(animateInScale, duration)
            .easing(easing)
            .onUpdate(() => {
                activeClip.container.scale.set(scale.x, scale.y, scale.z);
            })
            .start();
    };

    const handleVolumeButtonClick = (isVolumeOn) => {
        if (isVolumeOn) {
            exploreView.showVolumeMute();
            activeClip.setMuted(true);
        } else {
            exploreView.showVolumeOn();
            activeClip.setMuted(false);
        }
    };

    const handleCloseFREClick = () => {
        if (activeClip.IsClipReady && !activeClip.IsClipPlaying) {
            exploreView.showTip();
        }
    };

    const handleScreenShot = async (explore) => {
        const clipData = explore.ClipData;
        const ownerAvatarBase64 = explore.OwnerAvatarBase64;

        try {
            activeClip.pause();
            XR8.CanvasScreenshot.configure({ maxDimension: 1028, jpgCompression: 100 });
            const images = await Promise.all([
                createImageElement(ownerAvatarBase64),
                createImageElement(popinsLogoSrc),
                // createImageElement(poweredBySrc),
            ]);

            const data = await XR8.canvasScreenshot().takeScreenshot({
                onProcessFrame: ({ ctx, canvas }) => {
                    drawOverlay({
                        canvasContext: ctx,
                        width: canvas.width,
                        height: canvas.height,
                        artist: clipData.artistName.toUpperCase(),
                        song: clipData.songName.toUpperCase(),
                        mint: `${clipData.mint} OF ${clipData.totalMint}`,
                        nickname: `${clipData.ownerNickname.toUpperCase()}`,
                        ownerImage: {
                            el: images[0],
                            width: 30,
                            height: 30,
                        },
                        popinsImage: {
                            el: images[1],
                            width: 100,
                            height: 100,
                        },
                        // poweredByImage: {
                        //     el: images[2],
                        //     width: 110,
                        //     height: 27,
                        // },
                    });
                },
            });

            PreviewView.mount({
                imageSrc: data,
                onClose: () => {
                    if (activeClip.started) {
                        activeClip.play();
                    }
                },
            });
        } catch (err) {
            activeClip.play();
            console.error(err);
        }
    };

    const handlePlayPauseClick = () => {
        if (!hasFirstTap || !activeClip.IsClipReady) {
            return;
        }
        if (activeClip.IsClipPlaying) {
            activeClip.pause();
            exploreView.showPlay();
        } else {
            activeClip.play();
            exploreView.showPause();
        }
    }

    const handleSeeking = (time) => {
        if (!hasFirstTap || !activeClip.IsClipReady) {
            return;
        }
        activeClip.setCurrentTime(activeClip.getCurrentTime()+time);
    }

    // Populates Omnivor content into an XR scene and sets the initial camera position.
    const initXrScene = (canvas, scene, camera, threeJsRenderer) => {
        Omniweb.setPlayerInfo('Popins');

        // Enable shadows in the renderer.
        threeJsRenderer.shadowMap.enabled = true;

        // Create a container to hold the lights and a subcontainer for the Omnivor content.
        // Use this container to position the content in the scene.
        const omnivorContainerWrapper = new THREE.Object3D();
        omnivorContainerWrapper.position.set(0, 0, 0);
        scene.add(omnivorContainerWrapper);

        // Create a container to hold the Omnivor content.
        const omnivorContainer = new THREE.Object3D();
        omnivorContainer.position.set(0, 0, 0);
        omnivorContainerWrapper.add(omnivorContainer);

        // Add a light for shadows.
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(5, 10, 7);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.width = 2048;
        directionalLight.shadow.mapSize.height = 2048;
        directionalLight.target = omnivorContainerWrapper;
        omnivorContainerWrapper.add(directionalLight);

        // Add a plane that can receive shadows.
        const planeGeometry = new THREE.PlaneBufferGeometry(2000, 2000);
        planeGeometry.rotateX(-Math.PI / 2);
        const planeMaterial = new THREE.ShadowMaterial();
        planeMaterial.opacity = 0.67;
        const shadowPlane = new THREE.Mesh(planeGeometry, planeMaterial);
        shadowPlane.receiveShadow = true;
        scene.add(shadowPlane);

        // Set the initial camera position relative to the scene we just laid out.
        camera.position.set(0, 1.5, 2);

        const placeContent = (point) => {
            // Shrink the content down before placing it at the desired location so we can animate it in.
            activeClip.container.scale.set(animateOutScale, animateOutScale, animateOutScale);

            // Move to the new location.
            omnivorContainerWrapper.position.set(point.x, point.y, point.z);

            // Rotate to face the camera.
            const lookTarget = new THREE.Vector3(camera.position.x, point.y, camera.position.z);
            omnivorContainerWrapper.lookAt(lookTarget);

            // Animate the scale back up.
            animateIn(true);
        };

        // Handle taps to position content and start playing.
        const onTap = (e) => {
            // Ignore double-taps
            if (e.tapCount !== 1 || exploreView.IsFREOnDuty || !activeClip.IsClipReady) {
                return;
            }

            if (activeClip.IsClipBuffering) {
                exploreView.showLoading();
            } else {
                exploreView.clearBody();
            }

            if (!activeClip.IsClipPlaying && !hasFirstTap) {
                exploreView.showPlaybackControls();
                activeClip.play();
            }

            // Calculate tap position in normalized device coordinates (-1 to +1) for both components.
            const tapPosition = new THREE.Vector2(
                (e.center.x / window.innerWidth) * 2 - 1,
                -(e.center.y / window.innerHeight) * 2 + 1
            );

            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(tapPosition, camera);

            // Raycast against the floor plane.
            const intersects = raycaster.intersectObject(shadowPlane);

            // If the ray intersects the floor plane, place our content at the tapped location.
            if (intersects.length == 1 && intersects[0].object === shadowPlane) {
                placeContent(intersects[0].point);
            }

            hasFirstTap = true;
        };

        const onPinch = (e) => {
            if (exploreView.IsFREOnDuty || !activeClip.IsClipReady) {
                return;
            }

            if (e.type === 'pinchstart') {
                zooming = true;
                initialZoomScale = omnivorContainer.scale.x;
            } else if (e.type === 'pinch' && zooming) {
                let scale = initialZoomScale * e.scale;
                scale = Math.max(zoomScaleMin, Math.min(scale, zoomScaleMax));
                omnivorContainer.scale.set(scale, scale, scale);
            } else if (e.type === 'pinchend') {
                zooming = false;
            }
        };

        const onPan = (e) => {
            if (exploreView.IsFREOnDuty || !activeClip.IsClipReady) {
                return;
            }

            if (e.type === 'panstart') {
                panning = true;
                initialRotation = 0.0;
            } else if (e.type === 'pan' && panning) {
                // A speed of 2Pi/Width results in a 360 degree rotation for a drag across
                // the entire width of the screen. We calculate this each time because,
                // if the screen is rotated, width will change.
                const rotationSpeed = (2 * Math.PI) / window.innerWidth;
                // The current rotation, in radians. This value is not a delta from the last event, but
                // rather the total change since the gesture began.
                const rot = e.deltaX * rotationSpeed;
                const diff = rot - initialRotation;
                initialRotation += diff;
                // Rotate about the local Y-axis.
                omnivorContainer.rotateY(diff);
            } else if (e.type === 'panend') {
                panning = false;
            }
        };

        // DEBUG: You can uncomment these line to debug the app with no
        // clipId = '569bda2373134d75a8ebc6c0ed1e199b';
        let clipId;
        const clipIdParam = 'clipid';
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has(clipIdParam)) {
            clipId = urlParams.get(clipIdParam);
        }

        if (clipId) {
            exploreView.showLoading();
            activeClip = Clip.create(clipId, {
                threeJsRenderer,
                omnivorContainer,
                initialMuted: !exploreView.IsVolumeOn,
                onReady: (clip) => {
                    if (clip.clipInfo) {
                        exploreView.setClipInfo(clip.clipInfo);
                    }

                    if (exploreView.IsFREShown) {
                        if (!exploreView.IsFREOnDuty) {
                            exploreView.showTip();
                        }
                    } else {
                        exploreView.showFRE();
                    }
                },
                onBuffering: (buffering) => {
                    if (!exploreView.IsFREOnDuty && !exploreView.IsTipOnDuty) {
                        if (buffering) {
                            exploreView.showLoading();
                        } else {
                            exploreView.clearBody();
                        }
                    }
                },
                onStopped: () => {
                    EndCard.mount(activeClip, () => {
                        EndCard.unmount();
                        exploreView.showTip();
                    });
                },
                onError: () => {
                    exploreView.showError(`Sorry, we didn't find the video!`);
                },
                onClipInfoLoaded: (clipInfo) => {
                    fetchOwnerAvatar(clipInfo.ownerAvatar).then((base64) => {
                        if (base64) {
                            exploreView.OwnerAvatarBase64 = base64;
                        }
                    });
                },
                exploreView,
            });

            // Register for touch events to place content/start playing, pinch-to-zoom, and pan-to-rotate.
            const hammer = new Hammer(canvas);
            hammer.get('pinch').set({ enable: true });
            hammer.on('tap', onTap);
            hammer.on('pinchstart pinch pinchend', onPinch);
            hammer.on('panstart pan panend', onPan);
        } else {
            exploreView.showError(`Sorry, we didn't find the video!`);
        }
    };

    // Return a camera pipeline module that adds scene elements on start.
    return {
        // Camera pipeline modules need a name. It can be whatever you want but must be unique within
        // your app.
        name: 'omnivorPipelineModule',

        // onStart is called once when the camera feed begins. In this case, we need to wait for the
        // XR8.Threejs scene to be ready before we can access it to add content. It was created in
        // XR8.Threejs.pipelineModule()'s onStart method.
        onStart: ({ canvas }) => {
            // Get the 3js scene from XR8.Threejs.
            const { scene, camera: _camera, renderer } = XR8.Threejs.xrScene();
            camera = _camera;

            exploreView = ExploreView.mount({
                onVolume: handleVolumeButtonClick,
                onCloseFRE: handleCloseFREClick,
                onScreenShot: handleScreenShot,
                onPlayPause: handlePlayPauseClick,
                onSeeking: handleSeeking,
            });

            // Initialize the scene and Omnivor content.
            initXrScene(canvas, scene, camera, renderer);

            // Animation loop for TWEEN
            const animate = (time) => {
                requestAnimationFrame(animate);
                TWEEN.update(time);
            };
            animate();

            // Sync the xr controller's 6DoF position and camera paremeters with our scene.
            XR8.XrController.updateCameraProjectionMatrix({
                origin: camera.position,
                facing: camera.quaternion,
            });
        },
        onUpdate: () => {
            // activeClip.update();
            if (activeClip.IsClipPlaying) {
                activeClip.omnivorRenderer.update(camera);
            }
        },
    };
};
