const noop = () => {};
const defaultRenderer = {
  update: noop,
};

export default class Clip {
  constructor(
    clipId,
    initialMuted,
    onReady,
    onBuffering,
    onLoading,
    onStopped,
    exploreView
  ) {
    this.clipId = clipId;
    this.started = false;
    this.IsClipBuffering = false;
    this.IsClipPlaying = false;
    this.muted = initialMuted;
    this.loading = false;
    this.firstFrameLoaded = false;
    this.clipInfo = null;
    this.clipMetadata = null;
    this.hasError = false;
    this.omnivorRenderer = defaultRenderer;

    this.onReady = onReady;
    this.onBuffering = onBuffering;
    this.onLoading = onLoading;
    this.onStopped = onStopped;
    this.exploreView = exploreView;
  }

  get IsClipReady() {
    return (
      this.hasError === false &&
      this.clipMetadata &&
      this.omnivorRenderer &&
      this.firstFrameLoaded
    );
  }

  play() {
    this.started = true;
    if (this.IsClipReady && !this.IsClipPlaying) {
      this.omnivorRenderer.setMuted(this.muted);
      this.omnivorRenderer.play();
      this.IsClipPlaying = true;
      this.exploreView && this.exploreView.showPause();
    }
  }

  pause() {
    if (this.IsClipReady && this.IsClipPlaying) {
      this.omnivorRenderer.pause();
      this.IsClipPlaying = false;
    }
  }

  update() {
    if (this.IsClipReady && this.IsClipPlaying) {
      this.omnivorRenderer.update();
    }
  }

  setCurrentTime(time) {
    if (this.IsClipReady) {
      this.omnivorRenderer.setCurrentTime(time);
    }
  }

  getCurrentTime() {
    if (this.IsClipReady) {
      return this.omnivorRenderer.getCurrentTime();
    }
    return 0;
  }

  setMuted(muted) {
    if (this.IsClipReady) {
      this.muted = muted;
      this.omnivorRenderer.setMuted(this.muted);
    }
  }

  setData(clipMetadata, clipInfo, threeJsRenderer, omnivorContainer) {
    this.setLoading(false);
    const container = new THREE.Object3D();
    container.position.set(0, 0, 0);
    // https://stackoverflow.com/questions/34616054/three-js-object-visible-true-not-showing-up-right-away
    container.visible = true;

    this.clipMetadata = clipMetadata;
    this.clipInfo = clipInfo;
    this.container = container;
    this.omnivorRenderer = this.createTileRenderer(
      this.container,
      threeJsRenderer
    );
    omnivorContainer.add(this.container);

    // Set the URLs for the content.
    this.omnivorRenderer.setVideoUrl(
      clipMetadata['videoUrl'],
      clipMetadata['bytesUrl']
    );

    this.omnivorRenderer.setContentLoadedCallback(() => {
      if (!this.firstFrameLoaded) {
        this.firstFrameLoaded = true;
        this.IsClipBuffering = false;
        this.IsClipReady && this.onReady(this);
      }
    });
    this.omnivorRenderer.setVideoEndedCallback(() => {
      this.IsClipPlaying = false;
      this.onStopped(this);
    });
    this.omnivorRenderer.setVideoBufferingCallback((buffering) => {
      this.IsClipBuffering = buffering;
      this.onBuffering(buffering, this);
    });
    this.omnivorRenderer.setVolume(1.0);
    this.omnivorRenderer.prepare();
    this.omnivorRenderer.setMuted(false);

    this.IsClipReady && this.onReady(this);
  }

  createTileRenderer(container, threeJsRenderer) {
    const metadata = this.clipMetadata['metadata'];
    return new OmniRendererTHREE(threeJsRenderer, metadata, (mesh) => {
      mesh.frustumCulled = false;

      // Extract the rotation and translation parameters from the
      // metadata. Note: y and z are swapped. Transmission format
      // standard uses y as up where as our convention in our
      // rendering code is to use z as up.
      const rotation = new THREE.Euler();
      if (metadata && 'rotation' in metadata) {
        const R = metadata['rotation'];
        rotation.x = -OmniUtils.deg2Rad(R['x']);
        rotation.z = -OmniUtils.deg2Rad(R['y']);
        rotation.y = -OmniUtils.deg2Rad(R['z']);
      }

      const translation = new THREE.Vector3();
      if (metadata && 'translation' in metadata) {
        const T = metadata['translation'];
        translation.x = T['x'];
        translation.z = T['y'];
        translation.y = T['z'];
      }

      const scale = 1 / 1000.0;
      mesh.scale.set(scale, scale, scale);
      mesh.setRotationFromEuler(rotation);
      mesh.position.set(translation.x, translation.y, translation.z);

      // Rotate the mesh into THREE's coordinate system.
      const R = new THREE.Matrix4().makeRotationFromEuler(
        new THREE.Euler(-Math.PI / 2.0, 0.0, Math.PI)
      );
      mesh.applyMatrix4(R);

      // Add the mesh to the container.
      container.add(mesh);
    });
  }

  setLoading(loading) {
    this.loading = loading;
    this.onLoading && this.onLoading(this.loading, this);
  }

  static create(
    clipId,
    {
      threeJsRenderer,
      omnivorContainer,
      initialMuted,
      onReady,
      onLoading,
      onBuffering,
      onStopped,
      onError,
      onClipInfoLoaded,
      exploreView
    }
  ) {
    const clip = new Clip(
      clipId,
      initialMuted,
      onReady,
      onBuffering,
      onLoading,
      onStopped,
      exploreView
    );
    clip.setLoading(true);

    setTimeout(async () => {
      try {
        const clipMetaRequest = OmniNetwork.requestClipById(clipId);
        const clipInfoRequest = async () => {
          const response = await fetch(
            `${window.API_BASE_URL}/clips/${clip.clipId}`,
            {
              method: 'GET',
              mode: 'cors',
              referrerPolicy: 'origin',
            }
          );
          return response.json();
        };

        const [clipMetadata, clipInfo] = await Promise.all([
          clipMetaRequest,
          clipInfoRequest(),
        ]);

        clipInfo['artistLink'] = clipMetadata['metadata']['artist_link'];
        clipInfo['popinsLink'] = clipMetadata['metadata']['popins_link'];
        if (!clipInfo['ownerAvatar']) {
          clipInfo['ownerAvatar'] =
            clipMetadata['metadata']['popins_avatar_url'] ||
            'https://cdn.popins.io/popins-logo.png';
        }
        if (!clipInfo['ownerNickname']) {
          clipInfo['ownerNickname'] =
            clipMetadata['metadata']['popins_username'] || 'popinsdotio';
        }
        clip.setData(clipMetadata, clipInfo, threeJsRenderer, omnivorContainer);
        clip.setLoading(false);
        onClipInfoLoaded && onClipInfoLoaded(clipInfo);
      } catch (error) {
        console.error(error);
        clip.hasError = true;
        clip.setLoading(false);
        onError(error);
      }
    });

    return clip;
  }
}
